@import "../variables";
@import "../mixins";

.loader_wrapper{
    position: fixed;
    background: $siteColor;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    opacity: 1;
    .loader_inner{
        position: relative;
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }
    .logo_center{
        width: 100%;
    }
    .textAnimate{
        position: absolute;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        -webkit-animation:spin 6s linear infinite;
        -moz-animation:spin 6s linear infinite;
        animation:spin 6s linear infinite;
    }

    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }
}