@import "../variables";
@import "../mixins";

.blog_inner{
    padding: 50px 0;
    font-family: PoppinsRegular,sans-serif!important;

    .blog_date{
        text-align: center;
        margin-top: 30px;
    }
    .blogInner_image{
        width: 100%;
        position: relative;
        margin-top: 40px;
        max-height: 650px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }
    .blog_inner{
        padding: 60px 0;
    }
    .article_line{
        padding-bottom: 40px;
        display: flex;
        align-items: center;
        color: #BABABA;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;

        img{
            display: block;
            margin-left: 7px;
            width: 25px;
        }
    }
    .article_block{
        display: flex;
        align-items: center;
        span{
            display: block;
            margin-left: 20px;
            text-decoration: underline;
            color: rgba($siteColor, 0.7);
        }
    }
    .share_line{
        display: flex;
        align-items: center;
    }
    .social_list{
        display: flex;
        align-items: center;
        a{
            display: block;
            font-size: 35px !important;
            margin-left: 7px;
        }
    }
}
