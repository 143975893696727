@import "../variables";
@import "../mixins";


.team_wrapper{
    padding: 60px 0;
    .team_block {
        padding: 60px 0;
        margin-bottom: 40px;
        .custom_container{
            display: flex;
        }
    }
    .image_block{
        object-fit: contain;
        width: 65%;
      }
      .team_img {
        width: 50%;
        margin:auto;
      }
      .team_info {
        width: 50%
      }
    .button_block{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        button{
            font-size: 25px;
            line-height: 25px;
            background: transparent;
            color: $siteColor;
            transition: 0.3s;
            box-shadow: none;
            outline: none;
            cursor: pointer;
            border: none;
            &:hover{
                opacity: 1;
                color: $secondColor;
            }
        }
    }
    @media (max-width: 991px) {
      .team_block .custom_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
    }
    .image_block{
        object-fit: contain;
        padding: 0 ;
      }

  }
}
