@import "../variables";
@import "../mixins";


.services_wrapper {
    padding: 50px 0;

    .services_list {
        margin-top: 20px;
    }

    .service_title {
        font-family: $bold;
        margin-bottom: 40px;
        padding-top: 30px;
    }

    .service_block {
        margin-bottom: 35px;
    }

    .image_block {
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        vertical-align: top;
    }

    .inlineImg_section .goal_info {
        height: 100%;
        transition: height 0.5s ease-in-out;

        .info_inner {
            padding-left: 60px;
            max-width: 735px;
            height: 100%;
            transition: height 0.5s ease-in-out;

        }
    }

    .buttons_line {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .site_btn {
            margin-left: auto;
            max-width: 200px;
            width: 100%;
        }
    }

    .button_block {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            border: none;
            outline: none;
            font-size: 24px;
            line-height: 31px;
            cursor: pointer;
            background: transparent;
            color: $black;
            transition: 0.3s;
            display: flex;
            align-items: center;

            &.loadding {
                .spinner-border {
                    opacity: 1;
                }
            }

            .spinner-border {
                transition: 0.5s;
                opacity: 0;
                margin-left: 15px;
            }

            &:hover {
                opacity: 1;
                color: $siteColor;
            }
        }
    }

    .description_block{
        transition: 0.6s ease;
        display: grid;
        grid-template-rows: 0.28fr;
        align-items: baseline;
        overflow: hidden;
        min-height: 245px;
        &.active{
            grid-template-rows: 1fr;    
        }
    }

    .section_description {
        transition: height 0.5s ease-in-out;
        overflow: hidden;
        // height: 260px;

        &.active {
            height: 100% !important;
        }
    }

    .seeMore {
        font-size: 20px;
        line-height: 21px;
        color: #5A5A5A;
        opacity: 1;
        transition: 0.5s;

        &.hide {
            opacity: 0;
            pointer-events: none;
        }
    }

    @media (max-width: 991px) {

        .description_block{
            padding-right: 15px;
        }
        padding: 40px 0;

        .service_title {
            margin-bottom: 20px;
            padding-top: 20px;
        }

        .inlineImg_section .goal_info .info_inner {
            padding-left: 0;
            max-width: none;
            padding: 0 20px;
        }

        .site_btn {
            height: 45px;
            font-size: 16px;
            line-height: 24px;
            max-width: 170px;
            padding: 0 15px;
        }
    }
    @media (max-width: 575px) {
        .description_block {
            min-height: 230px;
        }
        .seeMore {
            font-size: 16px;
            line-height: 21px;
        }
        .buttons_line .site_btn {
            max-width: 160px;
        }
        .button_block button {
            font-size: 21px;
        }
    }
}

.service_inner{
    .absoluite_image{
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-repeat: top center !important;
    }
    .services_list {
       padding-bottom: 0 !important;
    }
    .description_block{
        display:  block !important;
    }
}