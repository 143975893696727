@import "../variables";
@import "../mixins";

.btn-call {
    background: $secondColor;
    border: 2px solid rgba($siteColor , 0.1);
    border-radius: 50%;
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3);
    cursor: pointer;
    height: 100px;
    width: 100px;
    text-align: center;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 999;
    transition: .3s;
    -webkit-animation: hoverWave linear 1s infinite;
    animation: hoverWave linear 1s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
		text-decoration: none;
}

.btn-call__ico {
    display: flex;
    justify-content: center;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    color: rgba($siteColor , 1);
    font-size: 16px;
    line-height: 22px;
    font-family: $semiBold;
    text-align: center;
    transition: .3s all;
}

.btn-call:hover {
    background-color: #fff;
}

.btn-call:hover .btn-call__ico {
    color: #38a3fd;
}

@-webkit-keyframes hoverWave {
    0% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
    }

    40% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
    }

    80% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067)
    }

    100% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0.0)
    }
}

@keyframes hoverWave {
    0% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
    }

    40% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
    }

    80% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067)
    }

    100% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0.0)
    }
}

@media (max-width: 1199px) {
    .btn-call {
        cursor: pointer;
        height: 80px;
        width: 80px;
        right: 40px;
        bottom: 40px;
    }
    .btn-call__ico {
        font-size: 14px;
        line-height: 20px;
    }
}

.support_fixed{
    .btn-call {
        bottom: 80px;
    }
}