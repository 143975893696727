@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?rjkqsj');
    src: url('../fonts/icomoon.eot?rjkqsj#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?rjkqsj') format('truetype'),
        url('../fonts/icomoon.woff?rjkqsj') format('woff'),
        url('../fonts/icomoon.svg?rjkqsj#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "PoppinsRegular";
    src: local("PoppinsRegular"),
        url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "PoppinsBold";
    src: local("PoppinsBold"),
        url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "PoppSemiBold";
    src: local("PoppSemiBold"),
        url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "PoppMedium";
    src: local("PoppMedium"),
        url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "PoppinsLight";
    src: local("PoppinsLight"),
        url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 500;
}


.icon-insta:before {
    content: "\e906";
}

.icon-fb:before {
    content: "\e907";
}

.icon-linkedin:before {
    content: "\e909";
}

.icon-phone:before {
    content: "\e90a";
}

.icon-earth:before {
    content: "\e90b";
}

.icon-fax:before {
    content: "\f02f";
}
.icon-location:before {
    content: "\e90c";
}

.icon-attach:before {
    content: "\e90d";
}

.icon-map:before {
    content: "\e900";
}

.icon-right:before {
    content: "\e901";
}

.icon-map-icon:before {
    content: "\e903";
}

.icon-calendar:before {
    content: "\e904";
}

.icon-calling:before {
    content: "\e905";
}

.icon-heart:before {
    content: "\e90f";
}

.icon-down:before {
    content: "\e902";
}

.icon-plus:before {
    content: "\e908";
}


@mixin standardList() {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

@mixin slider() {
    position: relative;
    white-space: nowrap;
    font-size: 0;
    overflow: hidden;
}

@mixin slide() {
    display: inline-block;
    white-space: normal;
    font-size: 1rem;
}

@mixin centerCover() {
    background-size: cover !important;
    background-position: center !important;
}

@mixin animStyle($parameters) {
    -o-transition: $parameters;
    -ms-transition: $parameters;
    -moz-transition: $parameters;
    -webkit-transition: $parameters;
    transition: $parameters;
}

@mixin animStyles($param1, $param2) {
    -o-transition: $param1, $param2;
    -ms-transition: $param1, $param2;
    -moz-transition: $param1, $param2;
    -webkit-transition: $param1, $param2;
    transition: $param1, $param2;
}

@mixin transStyle($parameters) {
    transform: $parameters;
    -moz-transform: $parameters;
    -ms-transform: $parameters;
    -webkit-transform: $parameters;
    -o-transform: $parameters;
}

@mixin torigin($parameters) {
    transform-origin: $parameters;
    -webkit-transfrom-origin: $parameters;
}

@mixin animDelay($parameters) {
    -o-transition-delay: $parameters;
    -ms-transition-delay: $parameters;
    -moz-transition-delay: $parameters;
    -webkit-transition-delay: $parameters;
    transition-delay: $parameters;
}

@mixin dropArrow() {
    color: inherit;
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
}

@mixin overHidden() {
    position: relative;
    overflow: hidden;
}

@mixin psevdoAbs() {
    display: block;
    content: "";
    position: absolute;
}

@mixin maskedInput() {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    visibility: hidden;
}

@mixin colCount($col) {
    -moz-column-count: $col;
    -webkit-column-count: $col;
    column-count: $col;
}

@mixin colGap($gap) {
    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content
    }

    :-moz-placeholder {
        @content
    }

    ::-moz-placeholder {
        @content
    }

    :-ms-input-placeholder {
        @content
    }
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin hoverEffect {
    transition: 0.3s;
    opacity: 1;
    color: $red !important;

    &:hover {
        opacity: 0.5;
    }
}
