@import "../variables";
@import "../mixins";


.error_wrapper{
    padding: 60px 0;
    h1 {
      font-weight: 700;
      font-size: 44px;
    }
    .not-found {
      color: #3BB8CF;
      font-weight: 500;
      line-height: 0 54px;
      font-size: 30px
    }
    img {
      height: 260px
    }
    .btn {
      background: #3BB8CF;
      border-radius: 4px;
      padding: 10px;
      color: #fff;
      margin: 50px
    }
}
