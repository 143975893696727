@import "../variables";
@import "../mixins";

.blog_wrapper{
    position: relative;
    padding-top: 55px;
    padding-bottom: 45px;
    font-family: PoppinsRegular,sans-serif!important;
    .blog_list{
        margin: 55px 0;
    }
    .blog_block{
        display: flex;
        gap: 80px;
        justify-content: space-between;
        margin-bottom: 45px;
    }
    .pagination_section{
        padding-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .image_block{
        max-width: 400px;
        width: 100%;
        img{
            width: 100%;
            height: auto;
        }
    }

    .active>.page-link, .page-link.active {
        z-index: 3;
        color: white!important;
        background-color: rgb(9, 128, 152) !important;
        border-color: rgb(9, 128, 152) !important;
    }

    .page-link {
        color: $siteColor !important;
    }

    .blog_info{
        flex: 1;
    }
    .blog_title{
        font-size: 24px;
        line-height: 21px;
        color: $black;
        font-family: $semiBold;
        &:hover{
            opacity: 1 !important;
            color: #098098;
        }
    }
    .blog_description{
        margin-top: 30px;
        font-size: 20px;
        line-height: 30px;
        color: $black;
    }

    @media (max-width: 1440px) {
        .blog_block {
            gap: 40px;
        }
    }

    @media (max-width: 1199px) {
        .blog_block {
            gap: 40px;
        }
        .blog_title {
            font-size: 20px;
            line-height: 22px;
        }
        .blog_description {
            margin-top: 20px;
            font-size: 16px;
            line-height: 27px;
        }
    }

    @media (max-width: 991px) {
        .image_block{
            max-width: none;
        }
        .blog_block {
            flex-direction: column;
        }
    }
}
