@import "./variables";
@import "./mixins";

.page_footer {
    background: $siteColor;
    position: relative;

    .footer_inner {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        padding: 50px 0;
    }

    .footer_logo a {
        max-width: 120px;
        display: flex;
        width: 100%;
        margin-top: -15px;

        img {
            width: 100%;
            height: auto;
        }
    }
    .footer_secial {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        color: $white;

        a {
            font-size: 40px;
            color: $white;
            transition: 0.3s;

            &:hover {
                opacity: 1;
                color: $secondColor;
            }
        }
    }

    .footer_menu {
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 45px;
        flex: 1;

        a ,span {
            display: flex;
            margin-bottom: 20px;
            color: $white;
            font-family: $regular !important;
            font-size: 16px;
            max-width: 250px;
            line-height: 19px;
            display: flex;
            align-items: center;
            &:hover{
                opacity: 1;
                color: $secondColor;
            }
            &:before {
                display: block;
                font-size: 22px;
                color: $secondColor;
                margin-right: 15px;
                font-family: "icomoon";
            }
            &.phone_link{
                white-space: nowrap;
                font-size: 21px;
                font-family: $medium !important;
                letter-spacing: 1px;
            }
            &.menu_title {
                font-size: 18px;
                line-height: 22px;
                font-family: $semiBold !important;
            }
        }
        span{
            pointer-events: none;
        }
        svg{
            display: block;
            font-size: 22px;
            color: $secondColor;
            margin-right: 15px;
        }
    }
    .services_li{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px;
        position: relative;
        padding-top: 41px;
        .menu_title{
            position: absolute;
            top: 0;
        }
        a{
            line-height: 27px;
            align-items: flex-start;
            margin-bottom: 10px;
        }
    }
    .social_list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: fit-content;
        gap: 12px;
        margin-left: auto;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            width: 40px;
            height: 40px;
            margin: 0;
            padding: 0;
        }
     
    }
    .payment_block div {
        display: flex;
        align-items: center;
        gap: 6px;

        span {
            width: 60px;
            height: 30px;
        }
    }

    .footer_copytoght {
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-align: center;
        line-height: 22px;
        height: 48px;
        margin-top: 24px;
        background: $secondColor;

        a {
            color: $white;
            text-decoration: underline;
            &:hover{
                opacity: 1;
                color: $siteColor;
            }
        }

    }

    .footer_logo {
        width: 140px;
        display: flex;
        justify-content: center;

        a {
            width: 100%;
            display: flex;
            justify-content: center;
            height: fit-content;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    @media (max-width: 1440px) {
        .footer_inner {
            gap: 30px;
            padding: 70px 0;
        }
    }

    @media (max-width: 1199px) {
        overflow: hidden;

        .footer_inner {
            grid-template-columns: 1fr;
            padding: 40px 0;
            display: grid;
            // padding: 100px 60px;
        }

        .services_li {
            grid-column-gap: 15px;
        }

        .footer_menu {
            grid-template-columns: auto auto auto;
            padding: 0 20px;
        }

        .footer_menu {
            gap: 20px;
        }

        .footer_logo a {
            max-width: 100px;
            margin-top: 0;
        }

        .social_list a {
            font-size: 35px;
            width: 35px;
            height: 35px;
        }
    }

    @media (max-width: 991px) {
        .footer_menu {
            grid-template-columns: 2.5fr 1fr;
        }
    }

    @media (max-width: 767px) {
        .payment_block {
            margin-top: 20px;
        }
         .services_li {
            grid-template-columns: 1fr;
        }
        .footer_logo {
            margin-bottom: 10px;
        }

        .footer_inner {
            grid-template-columns: 1fr;
        }

        .footer_menu {
            grid-template-columns: 1fr 1fr;
            gap: 30px;

            li:first-child {
                padding-left: 0;
            }
        }

        .payment_block {
            grid-column-start: auto;
            grid-column-end: auto;
        }
    }

    @media (max-width: 575px) {
        .footer_menu {
            gap: 50px;
            grid-template-columns: 1fr;
        }

        .footer_menu li:nth-child(2) {
            padding-left: 0;
        }

        .social_list {
            margin-left: 0;
        }

        .payment_block {
            margin-top: 20px;
            justify-content: flex-start;
        }

        .footer_copytoght {
            padding: 25px 20px;
            height: auto;
            line-height: 30px;
        }
    }
}