$siteColor: #00525D;
$secondColor : #3BB8CF;
$white: #ffffff;
$black: #000000;
$black33: #333333;
$btn-width: 120px;
$btn-height: 40px;
$block-padding: 60px;
$error: #F8647F;
$success: #005D4C;
$link: #13AAC7;
$warning: #FCD45C;

$regular: "PoppinsRegular";
$bold: "PoppinsBold";
$light: "PoppinsLight";
$medium: "PoppMedium";
$semiBold: "PoppSemiBold";

