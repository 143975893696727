@import "../variables";
@import "../mixins";

.physicians_wrapper{
    position: relative;
    padding-top: 55px;
    .block_label_blue {
      color: #4883c0 !important;
      font-size: 21px !important;
      font-weight: 700 !important;
      padding-top: 12px !important;
    }
    .section_title{
        padding-bottom: 30px;
    }
    .cover_title{
        max-width: 540px;
        font-size: 40px;
        line-height: 60px;
        // font-family: $medium;
        font-style: italic;
    }
    .referal_desc{
        margin-top: 50px;
    }

    @media (max-width: 1440px) {
        .cover_title {
            font-size: 32px;
            line-height: 50px;
        }
    }
    @media (max-width: 575px) {
        .cover_title {
            font-size: 24px;
            line-height: 42px;
        }
    }
}
