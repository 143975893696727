@import "../variables";
@import "../mixins";

.join_wrapper{
    position: relative;
    padding-top: 55px;
    .section_title{
        padding-bottom: 30px;
    }
    .sellect_section {
        position: relative;
    }
}