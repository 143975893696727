@import "./assets/scss/variables";
@import "./assets/scss/mixins";
@import "./assets/scss/header";
@import "./assets/scss/footer";

.custom_container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

* {
  padding: 0;
  margin: 0;
}

.main-content {
  height: 400px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box;
}

.uk-navbar-nav {
  gap: 0 !important;
}

body {
  padding-top: 235px;
  background: $white;
  position: relative;
  min-height: 50vh;
  color: $black;
  font-family: $regular ,  sans-serif;
  touch-action: manipulation;

  &.menu-opened {
    overflow: hidden;
  }
}

a {
  color: $siteColor;
  transition: 0.3s;
  outline: none !important;
  text-decoration: none;
  text-transform: none !important;

  &:hover {
    text-decoration: none !important;
    opacity: 0.7;
    outline: none !important;
  }
}

.error-info {
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  padding: 0;
  margin: 0;
  right: 0px;
  font-size: 14px;
  color: $error;
  bottom: -22px;
}

.has-error input,
.has-error .textarea_input,
.has-error .css-13cymwt-control,
.has-error .css-t3ipsp-control {
  border: 2px solid $error !important;
}

.has-error .input-group-text {
  border: 2px solid $error !important;
  border-right: none !important;
}

.form-block {
  position: relative;
}

.has-error .error-info {
  opacity: 1;
}

.site_btn {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  font-family: $regular;
  color: $white;
  padding: 0 18px;
  cursor: pointer;
  background: $siteColor;
  @include animStyle(0.3s);
  justify-content: center;
  box-shadow: none;
  border: none;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
    background: $secondColor;
    color: $white;
  }
}

.section_title {
  font-size: 36px;
  line-height: 45px;
  color: $black;
  font-family: $medium;
  margin-bottom: 20px;

  &.center_mode {
    text-align: center;
  }

  &.margin_none {
    margin: 0;
  }
}

.section_description {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 40px;
  color: $black;

  &.center_mode {
    text-align: center;
  }

  &.margin_none {
    margin: 0;
  }
}

.form_section {
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
  margin-top: 45px;
  position: relative;
  z-index: 5;
  padding: 80px 0 50px;

  .success_message {
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $white;
    height: 56px;
    height: 50px;
    background: $success;
    opacity: 0;
    transition: 0.5s;

    &.view {
      opacity: 1;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: rgba($white, 0.9);
    z-index: -1;
  }

  .form_container {
    max-width: 802px;
    width: 100%;
    margin: 0 auto;
  }

  .form_inner {
    width: 100%;
  }

  .form-block {
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    input {
      width: 100%;
      height: 65px;
      border-radius: 4px;
      background: rgba(#3BB8CF, 0.1);
      padding: 0 20px;
      border: 1px solid $secondColor;
      color: #818181;
      font-size: 16px;
      outline: none;
      line-height: 21px;

      &::file-selector-button {
        width: 0;
        border: none;
        padding: 0;
        padding-left: 20px;
        height: 65px;
        border-radius: 4px;
        background: transparent;
      }
    }
  }

  .block_label {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px;
    color: #474747;
  }

  .bottom_description {
    margin-top: 20px;
    font-size: 16px;
    color: #474747;
  }

  .bttom_inline {
    margin-top: 50px;
    display: flex;
  }

  .site_btn {
    margin-left: auto;
    max-width: 200px;
    width: 100%;
  }

  .attach_block {
    max-width: 345px;
    width: 100%;
    position: relative;
    cursor: pointer !important;

    input {
      cursor: pointer !important;
    }
  }

  .max_size {
    opacity: 0.5;
    color: #474747;
    margin-top: 10px;
  }

  .icon-attach {
    position: absolute;
    top: 50%;
    font-size: 18px;
    left: 20px;
    color: #818181;
    transform: translate(0%, -50%);
  }
}

.second_bg {
  background: rgba($secondColor , 0.10);
}

.accordion_container {
  position: relative;
  max-width: 802px;
  width: 100%;
  margin: 50px auto;

  .accordion-item {
    margin-bottom: 25px;
    border: 1px solid $secondColor !important;
    border-radius: 4px !important;
    overflow: hidden;
  }

  .accordion-button {
    outline: none !important;
    background: rgba($secondColor , 0.10);
    font-family: $medium;

    &:focus {
      border: none !important;
      box-shadow: none;
    }

    &:active {
      border: none !important;
      box-shadow: none;
    }

    &.accordion-button:not(.collapsed)::after {
      content: "-" !important;
    }

    &:after {
      content: "+" !important;
      width: 35px !important;
      height: 35px !important;
      border: none !important;
      background: $secondColor !important;
      color: $white !important;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50% !important;
    }
  }
}

.cover_Image {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
  position: relative;
  z-index: 5;
  height: 650px;
  display: flex;
  align-items: center;
  font-size: 40px;
  line-height: 50px;
  font-style: italic;
  color: $siteColor;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0px;
    background: linear-gradient(rgba($secondColor, 0.1), rgba($secondColor, 0.2), rgba($secondColor, 0.5));
  }
}

.page_section {
  padding: 50px 0;
}

.inlineImg_section {
  position: relative;
  padding: 0;

  .inlineImg_container {
    display: flex;
    min-height: 400px;

    .image_block {
      position: relative;
      flex: 0 0 45%;
      max-width: 45%;

      img {
        width: 100%;
        position: absolute;
        left: 0;
        object-fit: cover;
        top: 0;
        bottom: 0;
        height: 100%;
      }
    }
  }

  .goal_info {
    flex: 0 0 55%;
    max-width: 55%;
    display: flex;
    justify-content: flex-start;

    .info_inner {
      padding-left: 60px;
      max-width: 800px;
    }
  }
}


.form_sellect{
  position: relative;
  margin-bottom: 20px;
}
.css-13cymwt-control,
.css-t3ipsp-control {
    height: 65px;
    // border: 2px solid #1F9BF2;
    border: 1px solid #3BB8CF !important; 
    border-radius: 10px;
    padding: 0 15px;
    font-size: 17px;
    transition: 0.3s;
    background: rgba($secondColor , 0.10) !important;
    line-height: 20px;
    color: #B3B3B3;
    >div {
        height: 100% !important;
        display: flex;
        align-items: center;
        flex-wrap: unset !important;
    }
    .css-qbdosj-Input input {
      border: none  !important;
    }    
}
.sellect_section {
  position: relative;
}


@media (max-width: 1440px) {

  .cover_Image {
    height: 450px;
  }

  body {
    padding-top: 197px;
  }

  .site_btn {
    height: 50px;
    font-size: 16px;
    line-height: 24px;
    padding: 0 15px;
  }

  .section_title {
    font-size: 32px;
    line-height: 41px;
  }

  .section_description {
    font-size: 18px;
    line-height: 35px;
  }

  .form_section .form-block input {
    width: 100%;
    height: 50px;

    &::file-selector-button {
      height: 50px;
    }
  }
}

@media (max-width: 1199px) {
  body {
    padding-top: 187px;
  }

  .page_section {
    padding: 35px 0;
  }
}

@media (max-width: 991px) {

  .accordion_container {
    max-width: 840px;
    padding: 0 20px;
    margin: 30px auto;
  }

  .form_section {
    .form-block {
      margin-bottom: 15px;
    }

    .block_label {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 3px;
    }

    .bottom_description {
      margin-top: 15px;
      font-size: 14px;
    }

    .bttom_inline {
      margin-top: 35px;
      display: flex;
      flex-direction: column;
    }

    .site_btn {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .max_size {
    display: none;
  }

  .section_title {
    font-size: 28px;
    line-height: 35px;
  }

  .inlineImg_section {
    .inlineImg_container {
      flex-direction: column;

      .image_block {
        flex: none;
        max-width: 100%;
        width: 100%;
        height: 300px;
      }
    }

    .goal_info {
      flex: none;
      max-width: 100%;
    }
  }

  .section_description {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  body {
    padding-top: 0;
  }

  .homepage_wrapper .goal_info .info_inner {
    padding: 0 20px !important;
  }
}

@media (max-width: 575px) {
  .section_title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }

  .cover_Image {
    height: 350px;
  }

  .section_description {
    font-size: 14px;
    line-height: 26px;
  }

  .page_section {
    padding: 25px 0;
  }

}

.hero_section + div {
  display: grid;
  grid-template-columns: repeat(3,1 fr);
  padding: 50px 120px 0px 120px;
  background: #00525D;
  color: #fff;
}

.hero_section + div h3 {
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 40px;
  font-family: "PoppMedium";
}

.hero_section + div h3 + div {
  display: grid;
}

.hero_section + div h3 + div + div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero_section + div h3 + div + div + div {
  display: grid;
  justify-content: end;
  text-align: end;
}

.hero_section + div h3 + div + div + div p, .hero_section + div h3 + div {
  font-size: 24px;
  font-family: "PoppMedium";
}

.hero_section + div a {
  background: #3BB8CF;
}

.hero_section + div img {
  grid-column-start: 2;
  margin: 0 auto;
  position: relative;
  top: 10px;
}

@media(max-width:1199px) {
  .hero_section + div {
    display: block;
    position: relative;
    min-height: 590px;
    padding: 20px;
  }

  .hero_section + div h3 {
    font-size: 22px;
  }

  .hero_section + div h3 + div + div + div p, .hero_section + div h3 + div, .hero_section + div h3 + div p {
    text-align: center;
    font-size: 17px;
    margin: 0 !important;
  }

  .hero_section + div h3 + div + div {
    margin: 16px 0px;
  }

  .hero_section + div h3 + div + div + div {
    text-align: center;
    position: relative;
    z-index: 29;
    display: block;
  }

  .hero_section + div img {
    max-width: 200px;
    position: absolute;
    bottom: 0;
    top: unset;
    left: 0;
    right: 0;
  }

  .homepage_wrapper .hero_section {
    align-items: end;
  }

  .team_wrapper .team_info {
    width: 100%;
  }
}